<template>
	<div>
    <div style="height: 100vh" class="mobilehide">
        <div class="">
            <reviewhead></reviewhead>
        </div>
        <div class="public-global-left" >
            <el-scrollbar style="height:100%;overflow-x: hidden;">
                <reviewleft></reviewleft>
            </el-scrollbar>
        </div>
        <div class="public-global-right" >
            <el-scrollbar style="height:100%;overflow-x: hidden;">
                <router-view></router-view>
            </el-scrollbar>
        </div>
    </div>
	<div class="pchide">
		<div class="Student-Header-mobile">
			<i class="el-icon-arrow-left" style="color: #fff;font-size: 40px;" @click="backpage()"></i>
		</div>
		<div class="public-global-right" v-if="path!='/basic'">
		        <router-view></router-view>
		</div>
		<div  v-if="path=='/basic'" style="height: 110px;width:100%;background-color: #307ef3;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;margin-bottom:10px;">
			<div style="display:flex;">
				<div style="width:75px;margin:8px;">
					<img :src="userInfo.headerUrl" style="width:65px;height:65px;border-radius: 9px;"/>
				</div>
				<div style="width:255px;margin:8px;color:#fff;">
					<p>ID：{{userInfo.siteCode}}</p>
					<p>院校：{{userInfo.siteName}}</p>
				</div>
			</div>
		</div>
		<div v-for="(item, index) in tableData"  v-if="path=='/basic'" style="padding-left: 10px;padding-right: 10px;background-color: #fff;">
			
			<el-collapse v-model="activeName" accordion>
				<el-collapse-item :title="item.menuName" :name="index"  v-if="item.sysMenuVos!=null">
				<div v-if="item.sysMenuVos!=null" v-for="item2 in item.sysMenuVos">
					<div :index="item.path" :key="index" style="width: 100%;height: 28px;float: left;text-align: left;margin:5px 5px 5px 5px">
					  <i class="el-icon-menu" style="width: 35px;margin: 0px auto;color: rgb(56 71 92);"></i>
					  <span slot="title" style="font-size: 12px;">{{ item2.menuName }}</span>
					</div>
					
					<div v-if="item2.sysMenuVos!=null" v-for="item3 in item2.sysMenuVos">
						<div :index="item.path" :key="index" @click="toMenu(item3)" style="width: auto;height: 65px;float: left;text-align: center;margin:5px 5px 5px 5px">
						  <p>
						  <i class="el-icon-document" style="width: 35px;margin: 0px auto;color: rgb(56 71 92);font-size: 24px;"></i>
						  </p>
						  <span slot="title" style="font-size: 12px;">{{ item3.menuName }}</span>
						  
						</div>
					</div>
				</div>
			</el-collapse-item>
			</el-collapse>
		</div>
	</div>
	</div>
</template>

<script>
    //头部
    import reviewhead from "@/components/common/head";
    //左侧
    import reviewleft from "@/components/common/left";

    export default {
        name: "basic-index",
        components: {
            reviewhead,
            reviewleft
        },
		data() {
		    return {
				activeName:0,
		        visible: false,
		        tableData: [],
				path:"/basic",
				userInfo:{},
		    };
		},
		mounted() {
			this.path=this.$route.path;
		},
		created() {
			let userInfo = localStorage.getItem("userInfo");
			this.userInfo = JSON.parse(userInfo);
		    let list = localStorage.getItem('menuList');
		    this.tableData = JSON.parse(list);
			console.log(this.tableData,"tableData");
			this.path=this.$route.path;
			console.log(this.path);
		},
		methods:{
			  toMenu(item){
			  		  console.log(item);
					  this.path=item.menuUrl;
					  this.$router.push(item.menuUrl);
			  },
			  backpage(){
			  	this.$router.go(-1);
				this.path="/basic";
			  }
		}
    }
</script>

<style scoped>
    .el-scrollbar >>> .el-scrollbar__wrap { overflow-x: hidden; }
</style>